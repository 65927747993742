<script setup lang="ts">
import type { InitDataBanner } from "@/types";

const props = defineProps<{ banner: InitDataBanner }>();

const { t } = useT();
const { data: appInit } = useAppInitData();

const textColor = computed(() => props.banner?.promoOfferPreset?.bannerTextColor || "var(--neutral)");
const labelText = computed(() => {
	if (props.banner?.promoOfferPreset?.bestDeal) {
		return t("Best deal");
	}

	if (props.banner?.promoOfferPreset?.mostPopular) {
		return t("Most popular");
	}

	return props.banner?.promoOfferPreset?.subType || "";
});
const badgeLabel = computed(() =>
	props.banner?.promoOfferPreset?.badgeLabel ? parseJSON(props.banner?.promoOfferPreset?.badgeLabel) : {}
);
const labelColor = computed(() => {
	if (props.banner?.promoOfferPreset?.bestDeal) {
		return "var(--secondary-500)";
	}

	return props.banner?.promoOfferPreset?.mostPopular ? "var(--danger-300)" : "var(--success-600)";
});

const handleBannerClick = () => {
	dispatchGAEvent({
		event: "click_button",
		button_name: "deposit",
		location: "Your Personal Offer",
		quantity: appInit.value?.depositsCount ?? 0
	});
	dispatchGAEvent({
		event: "click_button",
		button_name: "deposit",
		location: "slider",
		step: "view_payments_info"
	});
	window?.$cash?.$store?.dispatch?.("cash/setOfferType", "promoOffer");
	window?.$cash?.$store?.dispatch?.("cash/setOfferId", props.banner?.promoOfferPreset?.id);
	window?.$cash?.$router?.push?.(`/cash/deposit-by-money/${props.banner?.promoOfferPreset?.id}/promoOffer/`);
};
</script>

<template>
	<MBanner
		class="offer"
		:image="banner?.promoOfferPreset?.image2x"
		:image-mobile="banner?.promoOfferPreset?.imageMobile"
		:second-image="banner?.promoOfferPreset?.secondImage2x"
		:second-image-mobile="banner?.promoOfferPreset?.secondImageMobile"
		:bg-img="banner?.promoOfferPreset?.imageBanner"
		:bg-img2x="banner?.promoOfferPreset?.imageBannerMobile"
		type="bannerPresetPackage"
		@click="handleBannerClick"
	>
		<template #default>
			<AText type="small" class="title" :modifiers="['uppercase', 'italic', 'bold']">
				<span v-if="labelText" class="title-type">{{ labelText }}</span>
				<span v-if="badgeLabel" class="title-label">{{ `${badgeLabel.percent} ${badgeLabel.text}` }}</span>
			</AText>
		</template>
		<template #description>
			<div class="d-flex flex-column gap-1 custom-color">
				<AText
					v-if="banner?.promoOfferPreset?.coins"
					type="h4 h2-md"
					class="money d-flex align-items-center justify-content-center justify-content-md-start"
				>
					<NuxtImg class="app-banner__desc-ico" src="/nuxt-img/banners/coins.png" format="webp" />
					<span>{{ numberFormat(banner?.promoOfferPreset?.coins) }}</span>
				</AText>
				<AText
					v-if="banner?.promoOfferPreset?.entries"
					type="h4 h2-md"
					class="money d-flex align-items-center justify-content-center justify-content-md-start"
				>
					<NuxtImg class="app-banner__desc-ico" src="/nuxt-img/banners/entries.png" format="webp" />
					<span>{{ numberFormat(banner?.promoOfferPreset?.entries) }} {{ t("For free") }} </span>
				</AText>
			</div>
		</template>
		<template #actions>
			<div class="d-flex flex-column gap-3 align-items-center actions">
				<AButton variant="primary" size="md" class="app-banner__btn">
					{{ t("buy now") }} {{ appInit?.currencySymbol }}{{ banner?.money }}
				</AButton>
				<AText
					v-if="banner?.usualPrice"
					type="x-small"
					:modifiers="['bold', 'uppercase']"
					class="d-flex align-items-center custom-color"
				>
					<span>{{ t("was") }} {{ appInit?.currencySymbol }}</span>
					<AText type="x-small" :modifiers="['lineThrough']">{{ banner?.usualPrice }}</AText>
				</AText>
			</div>
		</template>
	</MBanner>
</template>

<style scoped lang="scss">
.title {
	display: flex;
	align-items: center;
	gap: 4px;

	&-type {
		padding: 2px 3px;
		background: v-bind(labelColor);
		border-radius: 4px;
	}

	&-label {
		border-radius: 4px;
		padding: 1px 4px;
		border: 1px solid var(--neutral-50);
	}
}

.custom-color {
	color: v-bind(textColor);
}
</style>
